<template>
  <div class="ElementsComptable" >
    <validationCartouche
      :hasCedantValidated="elementsComptableActiviteCedee.has_cedant_validated"
      :hasRepreneurValidated="elementsComptableActiviteCedee.has_repreneur_validated"
    />
    <div class="container-fluid">
      <h2 class="gavc-h2">Éléments comptables de l’activité cédée </h2>

      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$emit('previous-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="elementsComptableActiviteCedee.has_repreneur_validated && elementsComptableActiviteCedee.has_cedant_validated"
          class="gavc-btn"
          @click="$emit('next-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>

      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="elementsComptableActiviteCedee-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="elementsComptableActiviteCedee-repreneur_comment-input"
            maxlength="1000"
            rows="5"
            @change="modifyElementsComptable"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="elementsComptableActiviteCedee.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="elementsComptableActiviteCedee.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{elementsComptableActiviteCedee.repreneur_comment}} </span>
      </div>

      <auditExplanation
        v-if="userGroupContains(['CEDANT'])"
        :isCedant="true"
        :isRepreneur="false"
      />

      <p class="gavc-chapo mt-4" v-else>
        <strong class="gavc-text-tory">En tant que repreneur</strong>,
        <u>vous n'avez aucun document ni information à compléter</u>,
        il vous est juste nécessaire de valider les documents chargés par le cédant
        puis de valider cette phase.
      </p>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.liasses-fiscale-collapse>
        Liasses fiscales
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="liasses-fiscale-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Liasses fiscales des années N-3, N-2 et N-1</strong>
          <span
            v-b-tooltip.hover title="Pour consulter la liasse fiscale, rendez-vous sur impôts.gouv.fr"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>

        <h4 class="gavc-h4 mt-4"> Liasse fiscale N-3 </h4>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'LIASSE_FISCALE_N3', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('LIASSE_FISCALE_N3', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'LIASSE_FISCALE_N3', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="LIASSE_FISCALE_N3"
        />

        <h4 class="gavc-h4 mt-4"> Liasse fiscale N-2 </h4>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'LIASSE_FISCALE_N2', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('LIASSE_FISCALE_N2', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'LIASSE_FISCALE_N2', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="LIASSE_FISCALE_N2"
        />

        <h4 class="gavc-h4 mt-4"> Liasse fiscale N-1 </h4>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'LIASSE_FISCALE_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('LIASSE_FISCALE_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'LIASSE_FISCALE_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="LIASSE_FISCALE_N1"
        />
      </b-collapse>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.extraits-grand-livre-collapse>
        Extraits du grand livre
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="extraits-grand-livre-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Extraits du grand livre des années N-3, N-2 et N-1</strong>
          <span
            v-b-tooltip.hover title="Pour consulter le grand livre, rapprochez vous de votre expert-comptable"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>

        <h4 class="gavc-h4  mt-4">Extraits du grand livre  N-3 </h4>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'EXTRAITS_GRAND_LIVRE_N3', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('EXTRAITS_GRAND_LIVRE_N3', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'EXTRAITS_GRAND_LIVRE_N3', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="EXTRAITS_GRAND_LIVRE_N3"
        />

        <h4 class="gavc-h4 mt-4">Extraits du grand livre  N-2 </h4>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'EXTRAITS_GRAND_LIVRE_N2', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('EXTRAITS_GRAND_LIVRE_N2', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'EXTRAITS_GRAND_LIVRE_N2', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="EXTRAITS_GRAND_LIVRE_N2"
        />

        <h4 class="gavc-h4 mt-4">Extraits du grand livre  N-1 </h4>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'EXTRAITS_GRAND_LIVRE_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('EXTRAITS_GRAND_LIVRE_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'EXTRAITS_GRAND_LIVRE_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="EXTRAITS_GRAND_LIVRE_N1"
        />
      </b-collapse>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.compte-resultat-bilan-collapse>
        Compte de résultat et bilan
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="compte-resultat-bilan-collapse" role="tabpanel">

        <h4 class="gavc-h4 mt-4"> Compte de résultat provisoire </h4>

        <div class="gavc-field mt-3">
          <label class="gavc-label" for="elementsComptableActiviteCedee-date_compte_resultat-input">
            Veuillez renseigner la date associée au compte de résultat provisoire (puis enregitrer vos informations)
            <span class="gavc-required"> * </span>
          </label>
          <div class="gavc-field gavc-field--icon">
            <input
              :disabled="!cedantCanModified"
              @input="$v.elementsComptableActiviteCedee.date_compte_resultat.$touch()"
              class="gavc-input gavc-input--icon gavc-input--calendar"
              id="celementsComptableActiviteCedee-date_compte_resultat-input"
              type="date"
              v-model="elementsComptableActiviteCedee.date_compte_resultat"
            >
            <i class="gavc-icon gavc-icon-calendar" ></i>
          </div>
        </div>


        <p class="gavc-p">
          Documents à charger :
          <strong v-if="!elementsComptableActiviteCedee.date_compte_resultat">
            Veuillez préalablement renseigner la date associée à votre compte de résultat provisoire (ci-dessus)
          </strong>
          <strong v-else>
            Compte de résultat provisoire en date du {{elementsComptableActiviteCedee.date_compte_resultat}}
          </strong>
        </p>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'COMPTE_RESULTAT_PROVISOIRE', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('COMPTE_RESULTAT_PROVISOIRE', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'COMPTE_RESULTAT_PROVISOIRE', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="COMPTE_RESULTAT_PROVISOIRE"
        />

        <h4 class="gavc-h4 mt-4"> Liste des immobilisations N-1 </h4>
        <p>
          Documents à charger : <strong>Liste des immobilisations N-1</strong>
        </p>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'IMMOBILISATIONS_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('IMMOBILISATIONS_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'IMMOBILISATIONS_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="IMMOBILISATIONS_N1"
        />

        <h4 class="gavc-h4 mt-4"> Liste des provisions N-1 </h4>
        <p>
          Documents à charger : <strong>Liste des provisions N-1</strong>
        </p>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsComptableActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsComptableActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'PROVISIONS_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsComptableActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsComptableActiviteCedee', false)"
          :items="filterDocuments('PROVISIONS_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'PROVISIONS_N1', elementsComptableActiviteCedee.id, 'elementsComptableActiviteCedee')"
          type="PROVISIONS_N1"
        />
      </b-collapse>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.expert-comptable-collapse>
        Expert comptable de la société
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="expert-comptable-collapse" role="tabpanel">

        <p class="gavc-p">
          Veuillez renseigner les coordonnées de votre expert comptable (puis enregistrer vos informations)
        </p>

        <div class="row">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.elementsComptableActiviteCedee.expert_comptable_first_name.$dirty ? $v.elementsComptableActiviteCedee.expert_comptable_first_name.$error : null  }">
              <label class="gavc-label" for="elementsComptableActiviteCedee.expert_comptable_first_name-group">
                Prénom
                <span class="gavc-required"> * </span>
              </label>
              <input
                :disabled="!cedantCanModified"
                class="gavc-input"
                id="elementsComptableActiviteCedee.expert_comptable_first_name-group"
                maxlength="128"
                @input="$v.elementsComptableActiviteCedee.expert_comptable_first_name.$touch()"
                type="text"
                placeholder="Prénom de votre expert comptable"
                v-model="elementsComptableActiviteCedee.expert_comptable_first_name"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.elementsComptableActiviteCedee.expert_comptable_last_name.$dirty ? $v.elementsComptableActiviteCedee.expert_comptable_last_name.$error : null  }">
              <label class="gavc-label" for="elementsComptableActiviteCedee.expert_comptable_last_name-group">
                Nom
                <span class="gavc-required"> * </span>
              </label>
              <input
                :disabled="!cedantCanModified"
                class="gavc-input"
                id="elementsComptableActiviteCedee.expert_comptable_last_name-group"
                maxlength="128"
                @input="$v.elementsComptableActiviteCedee.expert_comptable_last_name.$touch()"
                type="text"
                placeholder="Nom de votre expert comptable"
                v-model="elementsComptableActiviteCedee.expert_comptable_last_name"
              >
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.elementsComptableActiviteCedee.expert_comptable_raison_sociale.$dirty ? $v.elementsComptableActiviteCedee.expert_comptable_raison_sociale.$error : null  }">
              <label class="gavc-label" for="elementsComptableActiviteCedee.expert_comptable_raison_sociale-group">
                Raison sociale
                <span class="gavc-required"> * </span>
              </label>
              <input
                :disabled="!cedantCanModified"
                class="gavc-input"
                id="elementsComptableActiviteCedee.expert_comptable_raison_sociale-group"
                maxlength="250"
                @input="$v.elementsComptableActiviteCedee.expert_comptable_raison_sociale.$touch()"
                type="text"
                placeholder="Raison sociale de l'entreprise de votre expert comptable"
                v-model="elementsComptableActiviteCedee.expert_comptable_raison_sociale"
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': $v.elementsComptableActiviteCedee.expert_comptable_siren.$dirty ? $v.elementsComptableActiviteCedee.expert_comptable_siren.$error : null  }">
              <label class="gavc-label" for="elementsComptableActiviteCedee.expert_comptable_siren-group">
                Siren
                <span class="gavc-required"> * </span>
              </label>
              <input
                :disabled="!cedantCanModified"
                class="gavc-input"
                id="elementsComptableActiviteCedee.expert_comptable_siren-group"
                maxlength="9"
                @input="$v.elementsComptableActiviteCedee.expert_comptable_siren.$touch()"
                type="text"
                placeholder="Siren de l'entreprise de votre expert comptable"
                v-model="elementsComptableActiviteCedee.expert_comptable_siren"
              >
            </div>
          </div>
        </div>

        <div
          class="gavc-field mt-3"
          v-bind:class="{ 'is-error': $v.elementsComptableActiviteCedee.expert_comptable_address.$dirty ? $v.elementsComptableActiviteCedee.expert_comptable_address.$error : null  }">
          <label class="gavc-label" for="elementsComptableActiviteCedee.expert_comptable_address-group">
              Adresse
              <span class="gavc-required"> * </span>
          </label>
          <input
            :disabled="!cedantCanModified"
            class="gavc-input"
            id="elementsComptableActiviteCedee.expert_comptable_address-group"
            maxlength="250"
            @input="$v.elementsComptableActiviteCedee.expert_comptable_address.$touch()"
            type="text"
            placeholder="Adresse de l'entreprise de votre expert comptable"
            v-model="elementsComptableActiviteCedee.expert_comptable_address"
          >
        </div>
      </b-collapse>




      <!-- ######## CEDANT BUTTONS ########## -->
      <div v-if="!elementsComptableActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <validationSectionButton
            @modify="modifyElementsComptable"
            @validate="validateElementsComptable(true)"
          />
        </div>
      </div>
      <div v-else-if="elementsComptableActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="validateElementsComptable(false)"
          >
              Invalider cette étape
          </button>
        </div>
      </div>

      <div v-if="!elementsComptableActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
        <div class="col-12 text-right">
          <validationSectionButton
            @validate="validateElementsComptable(true)"
            :withModifyButton="false"
          />
        </div>
      </div>
      <div v-else-if="elementsComptableActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
        <div class="col-12 text-right">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="validateElementsComptable(false)"
          >
              Invalider cette étape
          </button>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="elementsComptableActiviteCedee.has_cedant_validated"
            text="Le cédant a validé toute la section"
          />
        </div>
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="elementsComptableActiviteCedee.has_repreneur_validated"
            text="Le repreneur a validé toute la section"
          />
        </div>
      </div>

      <div class="mt-4 text-center" v-if="elementsComptableActiviteCedee.has_repreneur_validated && elementsComptableActiviteCedee.has_cedant_validated">
        <button
          class="gavc-btn"
          @click="$emit('next-step')">
              Étape validée - Accéder à l'étape suivante
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { integer, minLength, maxLength, required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'

const apiConnectedService = new APIConnected()
const auditItem = () => import('@/components/audit/auditItem')
const auditExplanation = () => import('@/components/audit/auditExplanation')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')


export default {
  name: 'ElementsComptable',
  props: {
    id: {
      type: Number,
    }
  },
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingElementsComptable: false,
      elementsComptableActiviteCedee: {
        audit: {
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        cedant_comment: "",
        repreneur_comment: "",
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],

        date_compte_resultat: null,
        expert_comptable_first_name: "",
        expert_comptable_last_name: "",
        expert_comptable_raison_sociale: "",
        expert_comptable_siren: "",
        expert_comptable_address: "",
      },
    }
  },
  computed: {
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.elementsComptableActiviteCedee.has_cedant_validated
    },

    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.scrollToTop()
    this.getElementsComptable()
  },

  validations: {
    elementsComptableActiviteCedee: {
      date_compte_resultat: {
        required,
      },
      expert_comptable_first_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      expert_comptable_last_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      expert_comptable_raison_sociale: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      expert_comptable_siren: {
        integer,
        required,
        minLength: minLength(9),
        maxLength: maxLength(9)
      },
      expert_comptable_address: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      }
    },
  },
  methods: {
    filterDocuments (type, uploader) {
      return this.elementsComptableActiviteCedee.items.filter(item => item.type === type && item.uploader === uploader)
    },
    documentModified () {
      this.getElementsComptable()
    },
    getElementsComptable () {
      this.isGettingElementsComptable = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'elementsComptableActiviteCedee', this.id)
      .then((result) => {
        this.elementsComptableActiviteCedee = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('elementsComptableActiviteCedee getElementsComptable API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingElementsComptable = false
      })
    },

    modifyElementsComptable () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'elementsComptableActiviteCedee', this.id, this.elementsComptableActiviteCedee)
      .then((result) => {
        this.elementsComptableActiviteCedee = result.data
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          id: 'notification-toast',
          title: 'Informations mises à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('elementsComptableActiviteCedee modifyElementsComptable API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateElementsComptable (validationStatus) {
      const data = this.elementsComptableActiviteCedee
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, "elementsComptableActiviteCedee",  this.id, data)
      .then((result) => {
        this.elementsComptableActiviteCedee = result.data
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Section validée',
            variant: 'success',
            solid: true
          })
        }
            else {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('elementsComptableActiviteCedee validateElementsComptable API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>
